.close-icon {
    margin: 0 8px 0 8px !important;
    /* width: 37px !important; */
    height: 62px !important;
    background-color: #F0F7FD !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 14px !important;
    transition: .7s !important;
}

.close-overview-icon,
.close-crypto-prices,
.close-notepad-icon,
.close-transactions-icon,
.close-transactions-icon,
.close-upcomingevents-icon,
.close-settings-icon,
.close-message-icon,
.close-portfolio-icon {
    font-size: 2.5rem;
    color: #3F7DBC;
    transition: .5s !important;
    margin-right: 0;
}

.close-icon i {
    font-size: 2.3rem;
    color: #3F7DBC;
}

.close-right-icon {
    font-size: 1.3rem;
}



.close-icon-block {
    border-left: 6px solid white;
}

.close-icon-block:hover {
    background-color: #4bae0447 !important;
    /* opacity: 10%; */
    border-left: 6px solid #4CAE04;

}










.open-icon {
    margin: 0 8px 0 8px !important;
    /* width: 200px !important; */
    height: 50px !important;
    /* background-color: #F0F7FD !important; */
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 14px !important;
    transition: .5s !important;
}

.open-overview-icon,
.open-crypto-prices,
.open-notepad-icon,
.open-transactions-icon,
.open-transactions-icon,
.open-upcomingevents-icon,
.open-settings-icon,
.open-message-icon,
.open-portfolio-icon {
    font-size: 1.3rem;
    color: #3F7DBC;
    margin-right: 15px;
    transition: .5s !important;
}

.open-icon-block:hover .fade-in{
  color: #3F7DBC;
}


.open-icon-block {
    border-left: 6px solid white;
}

.open-icon-block:hover {
    background-color: #4bae0447 !important;
    /* opacity: 10%; */
    border-left: 6px solid #4CAE04;
    color: #3F7DBC;
    text-decoration: none !important;

}
a{
    text-decoration: none;
}



@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade-in {
    animation: fade-in .5s ease-in;
}